<template>
  <div class="page-container">
    <div class="dashboard-container">
      <div class="page-card" style="background-color: unset">
        <div class="page-card-boody">
          <div class="descriptions">
            <div class="descriptions-header">
              <div class="descriptions-title">欢迎访问 {{ app_name }}</div>
            </div>
            <div class="descriptions-view"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {APP_NAME} from "@/utils/config"

  export default {
    name: "Index",
    data() {
      return {
        app_name: APP_NAME,
      }
    },
    created() {

    },
    mounted() {

    },
    methods: {}
  }
</script>

<style lang="scss" scoped>

</style>
